<template>
  <div id="app">
    <b-container fluid>
      <b-row align-v="stretch">
        <b-col class="tracks-left-container d-none d-lg-block">
          <welcome-card :shortText="true"></welcome-card>
        </b-col>
        <b-col class="tracks-right-container">
          <img class="logo" src="../assets/logo.svg" alt="" />
          <b-row v-if="error" class="conv-title">Something went wrong</b-row>
          <div v-if="!error" class="player-container">
            <b-row class="conv-data-container">
              <b-col cols="3">
                <img
                  v-if="speaker == null || speaker == ''"
                  src="../assets/small_generic_user.png"
                  class="user-avatar"
                  alt=""
                />
                <img v-else class="user-avatar" :src="speaker" alt="" />
              </b-col>
              <b-col cols="9">
                <b-row class="conv-topic">{{ topic }}</b-row>
                <b-row class="conv-title">{{ title }}</b-row>
              </b-col>
            </b-row>
            <b-row class="audio-player-container">
              <audio-player
                ref="audioPlayer"
                :audio-list="audioList.map((elm) => elm.audioUrl)"
                :before-play="handleBeforePlay"
                :before-prev="handleBeforePrev"
                :before-next="handleBeforeNext"
                :show-playback-rate="false"
                :show-volume-button="false"
                theme-color="#214eb0"
              />
            </b-row>
            <b-row class="reaction-container">
              <b-col
                v-b-modal.modal-center
                cols="4"
                class="reaction-button-container"
              >
                <div class="conv-topic">Agree</div>
                <div class="reaction-button reaction-agree">
                  <img class="reaction-img" src="../assets/agree.svg" alt="" />
                </div>
              </b-col>
              <b-col
                v-b-modal.modal-center
                class="reaction-button-container"
                cols="4"
              >
                <div class="conv-topic">Debatable</div>
                <div class="reaction-button reaction-debatable">
                  <img
                    class="reaction-img"
                    src="../assets/debatable.svg"
                    alt=""
                  />
                </div>
              </b-col>
              <b-col
                v-b-modal.modal-center
                class="reaction-button-container"
                cols="4"
              >
                <div class="conv-topic">Disagree</div>
                <div class="reaction-button reaction-disagree">
                  <img
                    class="reaction-img"
                    src="../assets/disagree.svg"
                    alt=""
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="d-block d-lg-none">
            <div class="mobile-text ta-center">
              While the web version allows you to listen to specific bits of a
              conversation, through our mobile apps you can participate in any
              conversation.
            </div>
            <div class="ta-center">
              <img
                class="welcome-action mr-16"
                src="../assets/astore.svg"
                alt=""
              />
              <img class="welcome-action" src="../assets/gplay.svg" alt="" />
            </div>
          </div>
          <policies></policies>
        </b-col>
      </b-row>
      <b-modal
        footer-class="reaction-modal-footer"
        header-class="reaction-modal-header"
        content-class="reaction-modal-content"
        id="modal-center"
        centered
        title="dib8 Account needed"
      >
        <p class="my-4">
          In order to react in a conversation you need a dib8 account. Download
          the app on your mobile, and create an account.
        </p>
        <template #modal-footer>
          <img
            class="welcome-action"
            src="../assets/astore.svg"
            alt=""
            @click="
              window.location.href =
                'https://apps.apple.com/ro/app/dib8/id1572242341'
            "
          />
          <img
            class="welcome-action"
            src="../assets/gplay.svg"
            alt=""
            @click="
              window.location.href =
                'https://play.google.com/store/apps/details?id=com.dib8.app'
            "
          />
        </template>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import AudioPlayer from "@liripeng/vue-audio-player";
import WelcomeCard from "../components/WelcomeCard.vue";
import Policies from "../components/Policies.vue";
import axios from "axios";
export default {
  components: {
    AudioPlayer,
    WelcomeCard,
    Policies,
  },
  async created() {
    const trackId = this.$route.params.trackId;
    if (trackId) {
      try {
        var response = await axios.get(
          `https://vmi610555.contaboserver.net:5002/api/web/tracks/${trackId}`
        );
        let data = response.data;
        this.title = data.name;
        this.topic = data.topic;
        this.audioList = data.items;
        this.speaker = data.items[0].avatarUrl;
      } catch (e) {
        this.error = true;
      }
    } else {
      this.error = true;
    }
  },
  data() {
    return {
      topic: "",
      title: "",
      speaker: null,
      audioList: [],
      error: false,
      window: window
    };
  },
  methods: {
    handleBeforePlay(next) {
      this.speaker =
        this.audioList[this.$refs.audioPlayer.currentPlayIndex].avatarUrl;
      next();
    },
    handleBeforePrev(next) {
      this.$refs.audioPlayer.$refs.audio.currentTime = 0;
      next();
    },
    handleBeforeNext(next) {
      this.$refs.audioPlayer.$refs.audio.currentTime = 0;
      next();
    },
  },
};
</script>

<style>
.tracks-left-container {
  background: url("../assets/bg.png");
  background-size: cover;
  height: 100vh;
  padding-top: 25vh;
}

.mr-16 {
  margin-right: 1rem;
}

.tracks-right-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
  height: 100vh;
}

@media (min-width: 600px) {
  .player-container {
    width: 32rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media (max-width: 600px) {
  .player-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.player-container {
  border-radius: 1.25rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
  background: rgba(229, 243, 247, 0.81);
}

.audio-player {
  width: 100%;
}

.audio-player-container {
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 600px) {
  .logo {
    height: 6.125rem;
  }
}

@media (max-width: 600px) {
  .logo {
    height: 3.625rem;
  }
}

@media (max-width: 600px) {
  .user-avatar {
    height: 3.625rem;
    width: 3.625rem;
    border-radius: 2.5rem;
    object-fit: cover;
  }
}

@media (max-width: 600px) {
  .welcome-action {
    width: 8rem;
  }
}

@media (min-width: 600px) {
  .user-avatar {
    height: 5.125rem;
    width: 5.125rem;
    border-radius: 2.5rem;
    object-fit: cover;
  }
}

@media (min-width: 600px) {
  .conv-topic {
    font-family: "NunitoSemiBold";
    font-size: 1.313rem;
  }
}

@media (max-width: 600px) {
  .conv-topic {
    font-family: "NunitoSemiBold";
    font-size: 1rem;
  }
}

@media (min-width: 600px) {
  .conv-title {
    font-family: "NunitoBold";
    font-size: 1.625rem;
  }
}

@media (max-width: 600px) {
  .conv-title {
    padding-right: 0.5rem;
    font-family: "NunitoBold";
    font-size: 1.188rem;
  }
}

.conv-data-container {
  margin-bottom: 2rem;
}

.reaction-button-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media (min-width: 600px) {
  .reaction-button {
    cursor: pointer;
    border-radius: 0.5rem;
    height: 3.625rem;
    width: 6.063rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .reaction-button {
    cursor: pointer;
    border-radius: 0.5rem;
    height: 2.375rem;
    width: 4.813rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.reaction-agree {
  border: 0.047rem solid #3da025;
}

.reaction-debatable {
  border: 0.047rem solid #405f9a;
}

.reaction-disagree {
  border: 0.047rem solid #d50712;
}

@media (min-width: 600px) {
  .reaction-img {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 600px) {
  .reaction-img {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.reaction-container {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.reaction-modal-content {
  background-color: rgba(229, 243, 247, 1);
  border: none;
  border-radius: 1.25rem;
  padding-left: 1rem;
}

.reaction-modal-header {
  border-bottom: none;
  justify-content: center;
  padding-left: 1rem;
}

@media (min-width: 600px) {
  .reaction-modal-footer {
    display: flex;
    border-top: none;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 600px) {
  .reaction-modal-footer {
    display: flex;
    border-top: none;
    justify-content: space-between;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.mobile-text {
  font-family: "NunitoSemiBold";
  font-size: 0.875rem;
  padding: 1rem;
}

.ta-center {
  text-align: center;
}
</style>

